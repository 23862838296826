import { FC, useState, useEffect } from "react";
import useSWR from "swr";
import { EyeOffIcon } from "@heroicons/react/outline";

import { fetcher } from "../utils/fetcher";

export const NftCard = ({ details, onSelect, onTokenDetailsFetched = () => { }, selected }) => {
  const [fallbackImage, setFallbackImage] = useState(false);
  const { name, uri } = details?.data ?? {};
  const { data, error } = useSWR(
    uri,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
  useEffect(() => {
    if (!error && !!data) {
      onTokenDetailsFetched(data);
    }
    if (error) {
    setFallbackImage(true);
    }
  }, [data, error]);

  const onImageError = () => { 
    setFallbackImage(true);
  }
  const { image } = data ?? {};

  return (
    <>
      <div className={"col-6 pl-0 " + (fallbackImage ? "d-none" : "")}
      onClick={async () => onSelect({ ...details, data: { ...details.data, metadata: data } })}>
        {!fallbackImage || !error ? (
          <div className="container-card">
          <img
            src={image}
            onError={() => onImageError()}  style={{ backgroundColor: `rgb(43, 43, 43)`}}
            className={(selected ? 'card-img-top mb-3 border border-white' : "card-img-top")  + (details?.burned ? " opacity-50" : " ") }
          />
          <div className={"badge bottom-left title text-truncate bg-dark text-start" + (details?.burned ? "" : " d-none") } style={{maxWidth: '80%'}}>EXCHANGED</div>
          </div>
        ) : (
          // Fallback when preview isn't available
          // This could be broken image, video, or audio
          <div className="card-img-top mb-3">
            <EyeOffIcon className="h-16 w-16 text-white-500" />
          </div>
        )}
      </div>
    </>
  );
};