import { useWalletNfts } from "@nfteyez/sol-rayz-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl, Connection } from "@solana/web3.js";
import { useContext, useEffect, useState } from "react";
import { compile } from "sass";
import { getBurnedTokens, initOgBurn, processOgBurn } from "../../api/burn-nft.service";
import { LoadingBars } from "../../components/LoadingBars";
import { InfoModal } from "../../components/modals/InfoModal";
import { NftCard } from "../../components/NftCard";
import { ToastCongrats } from "../../components/toasts/CongratsToast";
import { AudioContext } from "../../contexts/audio.context";
import { AuthorizationContext } from "../../contexts/auth.context";
import { StyleThemeContext } from "../../contexts/style-theme.context";
import { burnNFT, burnNFTs, burnTx, checkForInvalidTokenAccountsAndExecute, checkForInvalidTokenAccountsInstructions, createBurnTx, signInstructions } from "../../services/burn.service";
import { getSolanaProvider } from "../../services/solana";
import { tokens } from './tokens.json';

const TOKENS = [
    ...tokens,
    ...[]
];

const URL = process.env.REACT_APP_RPC_URL ?? clusterApiUrl("devnet");
const SILLHOUTE = "https://i.imgur.com/1gMf6zk.png";
const SILLHOUTE_2 = "https://i.imgur.com/YWdR97x.png";
const SILLHOUTE_3 = "https://i.imgur.com/A7FltAi.png";
const cats = [SILLHOUTE, SILLHOUTE_2, SILLHOUTE_3];

const MINT_INFO_LINK = "https://twitter.com/degenfatcats/status/1541876877912162306";

const randomElement = () => cats[Math.floor(Math.random() * cats.length)];
export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const EvolveContainer = ({ wallet, onOpenInfoModal, onBurn, loading }: any) => {
    const [showDCF, setShowDCF] = useState(true);
    const [nfts, setNfts] = useState<any>();
    let { nfts: nftEyes, isLoading, error } = useWalletNfts({
        publicAddress: wallet?.publicKey?.toString(),
        connection: new Connection(URL, "processed"),
    });

    useEffect(() => {
        // if (!nfts?.length) {
            compileTokens(nftEyes);
        // }
    }, [nftEyes]);

    const compileTokens = async (nftEyes: any) => {
        let burnt = []
        try {
            const burnedTokens = await getBurnedTokens(wallet?.publicKey?.toString());
            burnt = burnedTokens.map((token: any) => ({
                mint: token?.tokenId,
                data: { uri: token?.uri },
                burned: true
            }));
        } catch (e) {
            console.log(e);
        }
        let activeNfts = [];
        if (nftEyes?.length) {
            activeNfts = nftEyes.filter((nft: any) => TOKENS.includes(nft.mint));
        }

        setNfts([...activeNfts, ...burnt]);
    }

    const handleToggle = (showDCF: any) => {
        setShowDCF(!showDCF);
    }

    return (

        <section className="position-relative pt-5">
            <div className="text-center my-3">
                <h3 className="mb-1 text-light">
                    Exchange your Coins for<br />Cats + WL
                </h3>
                <div className=""><a href="javascript:;" onClick={onOpenInfoModal} className="text-decoration-none">See more info <i className="fa-solid fa-circle-info fa-sm"></i></a></div>
            </div>
            <div className="container d-flex justify-content-center pt-sm-3 mb-3 pb-3 mt-sm-3">
                <div className="form-check form-switch mode-switch zindex-5">
                    <input disabled={!nfts?.length} type="checkbox" onChange={() => handleToggle(showDCF)} className="form-check-input" id="theme-mode" />
                    <label className="form-check-label d-block text-light" htmlFor="theme-mode">DCF</label>
                    <label className="form-check-label d-block text-light" htmlFor="theme-mode">DFC</label>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="card shadow-sm text-light" style={{ width: '360px', height: '542px', backgroundColor: `rgb(34, 34, 34)`, overflowY: 'scroll' }}>
                    <div className="card-body">
                        {/* <h5 className="text-light text-center">Wallet&nbsp;
                            <span className="fw-lighter opacity-50">({wallet?.publicKey?.toString().slice(0, 4)}...{wallet?.publicKey?.toString().substring(wallet?.publicKey?.toString().length - 4)})</span>
                        </h5> */}
                        <h5 className="card-title text-light text-center">
                            {
                                showDCF &&
                                <>
                                    DCF NFT {nfts?.length}
                                </>
                            }
                            {
                                !showDCF &&
                                <>
                                    DFC NFT {nfts?.length * 28}
                                </>
                            }
                        </h5>
                        <div className={"row g-3"}>
                            {
                                !!nfts?.length &&
                                <>
                                    {
                                        showDCF &&
                                        <>
                                            {
                                                nfts?.map((nft: any, index: any) => (
                                                    <NftCard key={index} details={nft} selected={false} onSelect={() => { }} />
                                                ))
                                            }
                                        </>
                                    }
                                    {
                                        !showDCF &&
                                        <>
                                            {
                                                Array.from({ length: nfts?.length * 28 }).map((_: any, i: any) => (
                                                    <div key={i} className="col-6">
                                                        <div className="container-card">
                                                            <img src={randomElement()} style={{ backgroundColor: `rgb(43, 43, 43)` }} className={"card-img-top"} />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    }

                                </>
                            }
                            {
                                !nfts?.length &&
                                <div className="d-flex">
                                    <div className="my-auto mx-auto">
                                        No DCF NFTs
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            {
                !!nfts?.length &&
                <div className="d-flex justify-content-center">
                    <div className="card-footer d-flex align-items-center fs-sm py-4">
                        <div className="d-flex align-items-center me-4">
                            <i className="fa-solid fa-coins me-1"></i>
                            {nfts?.filter((nft: any) => !nft.burned)?.length} EXCHANGEABLE
                        </div>
                        <div className="d-flex align-items-center me-4">
                            <i className="fa-solid fa-fire me-1"></i>
                            {nfts?.filter((nft: any) => nft.burned)?.length} EXCHANGED
                        </div>
                        <div className="d-flex align-items-center">
                            <i className="fa-solid fa-key me-1"></i>
                            {nfts?.filter((nft: any) => nft.burned)?.length ? '+' : ''}{nfts?.filter((nft: any) => nft.burned)?.length} WL AVAILABLE
                        </div>
                    </div>
                </div>
            }
            {
                !nfts?.length &&
                <div className="text-center mt-3 mb-2 fw-bold">
                    <>
                        <span className="text-danger">Please connect a wallet holding DCF NFTs.</span>
                    </>
                </div>
            }
            <div className="d-flex justify-content-center">
                {
                    !nfts?.length &&
                    <>
                        <button type="button" style={{ width: '360px' }} className="btn btn-dark px-3 px-sm-4" onClick={() => openInNewTab(MINT_INFO_LINK)}>
                            <span className="">See more info on our July 19th Mint</span>
                            <i className="ms-2 fa-solid fa-external-link"></i>
                        </button>
                    </>
                }
                {
                    !!nfts?.length &&
                    <>
                        <div className="d-flex justify-content-center">
                            {
                                !loading &&
                                <>
                                    <button type="button" disabled={!nfts?.filter((nft: any) => !nft.burned)?.length} style={{ width: '360px' }} className="btn btn-primary px-3 px-sm-4" onClick={() => onBurn(nfts)}>
                                        <i className="me-2 fa-solid fa-handshake-simple"></i>
                                        <span className="">Exchange {nfts?.filter((nft: any) => !nft.burned)?.length > 8 ? 8 : nfts?.filter((nft: any) => !nft.burned)?.length} DCFs for {nfts?.filter((nft: any) => !nft.burned)?.length > 8 ? 8 * 28 : nfts?.filter((nft: any) => !nft.burned)?.length * 28} Cats + Claim {nfts?.filter((nft: any) => !nft.burned)?.length > 8 ? 8 : nfts?.filter((nft: any) => !nft.burned)?.length} WLs</span>
                                    </button>
                                </>
                            }
                            {
                                loading &&
                                <LoadingBars />
                            }
                        </div>
                    </>
                }
            </div>
            <div className="d-flex justify-content-center mt-2">
                <small>Max 8 per transaction</small>
            </div>
        </section>
    )
}

export const Evolve = () => {
    const wallet = useWallet();
    const { style } = useContext(StyleThemeContext);
    const { auth, signIn, signInViaLedger } = useContext(AuthorizationContext);
    const { play } = useContext(AudioContext);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const handleInfoModalOpen = () => setShowInfoModal(true);
    const handleInfoModalClose = () => setShowInfoModal(false);

    const [loading, setLoading] = useState<any>(false);
    const enableLoading = () => setLoading(true);
    const disableLoading = () => setLoading(false);

    const [congratsToastShow, setCongratsToastShow] = useState<boolean>(false);

    useEffect(() => {
        if (wallet.connected) {
            handleInfoModalOpen();
        }
    }, [wallet])

    const onBurn = async (arr: any[]) => {
        const nfts = arr.filter(a => !a.burned);
        enableLoading();
        try {

            const firstTwoNfts = nfts?.slice(0, nfts?.length > 8 ? 8 : nfts?.length).map((nft: any) => ({
                uri: nft?.data?.uri,
                mint: nft?.mint
            }));


            const { id } = await initOgBurn(firstTwoNfts, auth?.idToken);

            const mints = firstTwoNfts.map((token: any) => token.mint);

            let tx = null;
            try {
                tx = await burnNFTs(wallet, mints, id);
            } catch (e) {
                disableLoading();
                console.log(e);
                return;
            }

            const payload = await processOgBurn(id, tx, auth?.idToken);
            play(14);
            setCongratsToastShow(true);

            setTimeout(() => {
                window.location.reload();
            }, 5000);

        } catch (e) {
            console.log(e);
            disableLoading();

        }
        // disableLoading();
    }

    const onCloseInfoModal = async () => {
        enableLoading();
        try {
            await signIn(
                wallet?.publicKey?.toString() as string,
                `` as string
            );
        }
        catch {
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();
    }

    const onLoginViaLedger = async () => {
        enableLoading();
        try {
            await signInViaLedger(wallet);
        }
        catch {
            disableLoading();
            return;
        }

        disableLoading();
        handleInfoModalClose();
    }

    const checkIfTokensAreOkay = async (arr: any[]) => {
        const nfts = arr.filter(a => !a.burned);
        enableLoading();
        const mints = nfts.map((token: any) => token.mint);

        try {
            const tx = await checkForInvalidTokenAccountsAndExecute(wallet, mints);
            console.log(tx);
            await onBurn(arr);
        } catch (e:any) {
            console.log(e);
        }

        disableLoading();
    }

    return (
        <>
            <ToastCongrats show={congratsToastShow} onClose={() => setCongratsToastShow(false)}></ToastCongrats>
            {
                showInfoModal &&
                <InfoModal
                    wallet={wallet}
                    show={showInfoModal}
                    isAuth={!!auth}
                    loading={loading}
                    styleCss={`dark`}
                    onLoginViaLedger={onLoginViaLedger}
                    onClose={() => onCloseInfoModal()}
                />
            }
            {
                !wallet.connected &&
                <section className="h-100vh d-flex">
                    <WalletMultiButton style={{ margin: 'auto' }} />
                </section>
            }
            {
                wallet.connected &&
                <>
                    <EvolveContainer wallet={wallet} onOpenInfoModal={handleInfoModalOpen} onBurn={checkIfTokensAreOkay} loading={loading} />
                </>
            }
        </>
    )
}