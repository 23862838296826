


import { get } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-api.degencoinflip.com/v2';

export const getSnapshots = async (community = 'DCF') => {
    const url = `${BASE_URL}/snapshots?community=${community}`;
    const response = await get(url);
    return response?.payload;
}

export const getSnapshotHolders = async (id: any) => {
    const url = `${BASE_URL}/snapshots/${id}/holders`;
    const response = await get(url);
    return response?.payload;
}