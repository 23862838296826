import { DateTime } from 'luxon';
import { createContext, useEffect, useState } from 'react';
import { getSiteStats } from '../api/hersch.service';
import { queryLatestCoinFlips } from '../api/queries.service';

interface SiteStatsContextValue {
  siteStats: any;
  fetchSiteStats(): void;
}

const SiteStatsContext = createContext<SiteStatsContextValue>({
  siteStats: [],
  fetchSiteStats() { }
});

const SiteStatsProvider = (props: any) => {
  // The observable will be used to set local state, so we also
  // need to use useState.
  const [siteStats, setSiteStats] = useState();
  // We'll initialize the observable with appState. All subscribers 
  // will have this same initial state.
  // const [observable, setObservableState] = useObservable(SiteStats);

  // useEffect(() => {
  //   const myObservable = observable?.pipe(
  //     // Here is where we'd add RxJs operators to make magic happen.
  //     // https://rxjs-dev.firebaseapp.com/guide/operators
  //   )

  //   myObservable?.subscribe({
  //     next: (v: any) => {
  //       setSiteStats(v);
  //     }
  //   });

  //   // When the component unmounts we'll unsubscribe from events
  //   return observable?.unsubscribe;
  // }, [observable])


  useEffect(() => {
    fetchSiteStats();
  }, [])

  const fetchSiteStats = async () => {
    const stats = await getSiteStats();
    // setObservableState(recentFlips);
    setSiteStats(stats);
  };

  return (
    <SiteStatsContext.Provider value={{ siteStats, fetchSiteStats }}>
      {props.children}
    </SiteStatsContext.Provider>
  )
};

export { SiteStatsContext, SiteStatsProvider };
