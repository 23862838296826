import { useWallet } from "@solana/wallet-adapter-react";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchBalance, getCurrentBalance } from "../../api-smart-contracts/dcf";
import { getDistribution, getDistributionTransactions, processDistribution, processDistributionTransaction } from "../../api/distributions.service";
import { getSnapshotHolders, getSnapshots } from "../../api/snapshots.service";
import { LoadingBars } from "../../components/LoadingBars";
import { MemoModal } from "../../components/modals/MemoModal";
import { NewDistributionModal } from "../../components/modals/NewDistributionModal";
import { AuthorizationContext } from "../../contexts/auth.context";
import { useInterval } from "../../hooks/useInterval";
import { DistributionStatus } from "../../utils/constants";
import { deMemoize } from "../../utils/helpers";

const DEFAULT_INTERVAL = 5000;

export const openInNewTab = (url: any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const impl = {
    compute: (items: any) => {
        const map = items?.reduce((prev: any, next: any) => {
            const {
                walletId
            } = next;
            if (walletId in prev) {
                prev[walletId].amount += 1;
            }
            else {
                prev[walletId] = {
                    ...next,
                    amount: 1,
                };
            }
            return prev;
        }, {});

        const result = Object.keys(map).map(id => map[id]);
        return result;
    }
};

const Instruction = ({ instruction }: any) => {
    const [showMemoModal, setShowMemoModal] = useState(false);
    const handleMemoModalOpen = () => setShowMemoModal(true);
    const handleMemoModalClose = () => setShowMemoModal(false);
    return (
        <>
            <div className="">
                {instruction?.totalSol} SOL to <b>{instruction?.walletId}</b> |&nbsp;
                <a href="javascript:;" className="text-decoration-none" onClick={handleMemoModalOpen}>see tokens ({deMemoize(instruction?.memo)?.tokens?.length}) </a>
            </div>
            {
                showMemoModal &&
                <MemoModal
                    memo={instruction?.memo}
                    show={showMemoModal}
                    onHide={() => handleMemoModalClose()}
                />
            }
        </>
    );
};

const processTx = async (transaction: any) => {
    console.log(transaction);
    if (transaction.status === DistributionStatus.PROCESSING) {
        const payload = await processDistributionTransaction(transaction?.distributionId, transaction?.transactionId);
        console.log(payload);
    }
}

const DistributionTransactionCard = (({ transaction, index, expandAll }: any) => {
    const [expand, setExpand] = useState(false);
    const [isRetrying, setIsRetrying] = useState(false);

    return (
        <>
            <div key={transaction?.id} className="card d-sm-flex flex-sm-row align-items-sm-center justify-content-between border-0 shadow-sm p-3 p-md-4 mb-4">
                <div className="d-flex align-items-center pe-sm-3">
                    <img src="https://i.imgur.com/WtWzHUL.png" width={84} alt="pfp" />
                    <div className="ps-3 ps-sm-4">
                        <h5 className="mb-1">Transaction #{index + 1}&nbsp;
                            {
                                transaction?.status === DistributionStatus.STARTED &&
                                <span className="badge bg-primary ms-1">READY</span>
                            }
                            {
                                transaction?.status === DistributionStatus.PROCESSING &&
                                <span className="badge bg-danger ms-1">PROCESSING</span>
                            }
                            {
                                transaction?.status === DistributionStatus.FINALIZED &&
                                <span className="badge bg-success ms-1">EXECUTED</span>
                            }
                        </h5>
                        <h4 className="mb-0">{transaction?.totalSol} SOL</h4>
                        <div className={"fs-sm" + (transaction?.signature?.length > 0 ? '' : ' d-none')}>Signature <a href="javascript:;" onClick={() => openInNewTab(`https://solscan.io/tx/${transaction.signature}`)}>{transaction?.signature}</a></div>
                        {
                            (expand && !expandAll) &&
                            <>
                                <div className="fs-sm mt-2"><a href="javascript:;" onClick={() => setExpand(false)}>Show less <i className="fas fa-chevron-up"></i></a></div>
                            </>
                        }
                        {
                            !expand && !expandAll &&
                            <div className="fs-sm mt-2"><a href="javascript:;" className="text-decoration-none" onClick={() => setExpand(true)}>See {transaction?.instructions?.length} recipients <i className="fas fa-chevron-down"></i></a></div>
                        }
                        {
                            (expand || expandAll) &&
                            <>
                                <hr className="my-3" />
                                {
                                    transaction?.instructions
                                        .sort((a: any, b: any) => b.totalSol - a.totalSol)
                                        .map((instruction: any) => <Instruction instruction={instruction}></Instruction>)
                                }
                            </>
                        }
                    </div>
                </div>
                <div className="d-flex justify-content-end pt-3 pt-sm-0">
                    {/* {
                    holder?.banned &&
                    <button type="button" className="btn btn-outline-secondary px-3 px-xl-4">
                        <i className="bx bx-edit fs-xl me-lg-1 me-xl-2" />
                        <span className="d-none d-lg-inline">UNBAN</span>
                    </button>}*/}
                {
                    !isRetrying && DateTime.fromISO(transaction.modifiedAt) < DateTime.utc().minus({ seconds: 30 }) && transaction?.status === DistributionStatus.PROCESSING &&
                    <button type="button" onClick={() => { processTx(transaction); setIsRetrying(true) }} className="btn btn-outline-primary px-3 px-xl-4">
                        <span className="d-none d-lg-inline">RETRY</span>
                    </button>

                } 
                </div>
            </div>
        </>
    )
});


export const Distribution = () => {
    const params = useParams();
    const { auth, signIn, signNonce } = useContext(AuthorizationContext);

    const [distributionDetails, setDistributionDetails] = useState<any>();
    const [distributionTransactions, setDistributionTransactions] = useState<any>();

    const [currentBalance, setCurrentBalance] = useState(0);
    const [expandAll, setExpandAll] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingTxes, setLoadingTxes] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        init();
    }, [])
    const init = async () => {
        const id = params?.id;

        const dist = await getDistribution(id);
        setDistributionDetails(dist);
        setLoading(false);

        refreshTransactions(dist);
    }

    const refreshTransactions = async (dist: any) => {
        const txes = await getDistributionTransactions(dist?.id);
        setDistributionTransactions(txes);
        setLoadingTxes(false);

        const done = dist?.status === DistributionStatus.PROCESSING &&
            txes?.findIndex((tx: any) => tx.status === DistributionStatus.PROCESSING) === -1;
        if (done) {
            setDistributionDetails({
                ...dist,
                status: DistributionStatus.FINALIZED
            });
        }
    }

    useInterval(async () => {
        if (distributionDetails?.escrow?.length && distributionDetails.status === DistributionStatus.STARTED) {
            const balance = await fetchBalance(distributionDetails?.escrow);
            console.log(balance);
            setCurrentBalance(balance);
        }
        if (distributionDetails?.status === DistributionStatus.PROCESSING) {
            refreshTransactions(distributionDetails);
        }
    }, DEFAULT_INTERVAL);

    const sendDistribution = async (id: any) => {
        setLoadingButton(true);
        try {
            const signature = await signNonce();
            console.log(signature);
            const payload = await processDistribution(id, signature, auth?.idToken);
            console.log(payload);
            setDistributionDetails(payload);
            setLoadingButton(false);
        } catch (e) {
            setLoadingButton(false);
            console.log(e);
        }
    }


    return <div className="container">
        <div className="mt-5 pt-5 mb-1 rounded-3 d-flex">
            <div className="container-fluid pt-5 pb-0">
                <Link to={`/holders`} className="text-decoration-none">
                    <span className="fas fa-chevron-left me-2"></span> BACK TO HOLDERS
                </Link>
                {
                    !loading &&
                    <div className="d-sm-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
                        {
                            distributionDetails?.status === DistributionStatus.STARTED &&
                            <div>
                                <h5 className="mb-sm-0">Please send </h5>
                                <h2 className="">{+(distributionDetails?.totalSol + distributionDetails?.fee).toFixed(7)} SOL </h2>
                                <h5 className="mb-sm-0">To This Address </h5>
                                <h4 className="mb-sm-0">{distributionDetails?.escrow} </h4>
                            </div>
                        }
                        {
                            distributionDetails?.status === DistributionStatus.PROCESSING &&
                            <div>
                                <h5 className="mb-sm-0">Distributing...</h5>
                                <h2 className="mb-sm-0">{distributionDetails?.totalSol} SOL</h2>
                            </div>
                        }
                        {
                            distributionDetails?.status === DistributionStatus.FINALIZED &&
                            <div>
                                <h3 className="mb-sm-0">Distributed {distributionDetails?.totalSol} SOL</h3>
                                <h6 className="mb-sm-0">{DateTime.fromISO(distributionDetails?.modifiedAt).toFormat('MMMM dd, yyyy')}</h6>
                            </div>
                        }
                    </div>
                }

                {
                    loading &&
                    <LoadingBars />
                }
            </div>
            <img src="https://i.imgur.com/896fn7R.png" className="img-fluid" style={{ maxWidth: '220px', maxHeight: '220px' }} />
        </div>
        <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
            <div className="d-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
                <h1 className="h2 mb-0">Showing {distributionTransactions?.length} Transactions</h1>
                {
                    loadingButton &&
                    <div className="ms-auto me-5 pe-5">
                        <LoadingBars />
                    </div>
                }
                {
                    !loadingButton &&
                    <>
                        {
                            distributionDetails?.status === DistributionStatus.STARTED &&
                            <>
                                {
                                    currentBalance >= distributionDetails?.totalSol &&
                                    <button type="button" className="btn btn-danger px-3 px-sm-4" onClick={() => sendDistribution(distributionDetails?.id)}>
                                        <i className="fa-solid fa-paper-plane  me-2"></i>
                                        <span className="d-none d-sm-inline">SEND DISTRIBUTION</span>
                                    </button>
                                }
                                {
                                    currentBalance < distributionDetails?.totalSol &&
                                    <button type="button" className="btn btn-danger-outline px-2 px-sm-3 disabled">
                                        <div className="fa-lg">
                                            <i className="fa-solid fa-circle-notch fa-spin me-2"></i>
                                        </div>
                                        <span className="d-none d-sm-inline">AWAITING {+(distributionDetails?.totalSol + distributionDetails?.fee - currentBalance).toFixed(7)} SOL</span>
                                    </button>
                                }
                            </>
                        }
                        {
                            distributionDetails?.status === DistributionStatus.PROCESSING &&
                            <>
                                <button type="button" className="btn btn-danger-outline px-2 px-sm-3 disabled">
                                    <div className="fa-lg">
                                        <i className="fa-solid fa-circle-notch fa-spin me-2"></i>
                                    </div>
                                    <span className="d-none d-sm-inline">PROCESSING</span>
                                </button>
                            </>
                        }
                        {
                            distributionDetails?.status === DistributionStatus.FINALIZED &&
                            <>
                                <button type="button" className="btn btn-success px-2 px-sm-3 disabled">
                                    <div className="fa-lg">
                                        <i className="fa-solid fa-check me-2"></i>
                                    </div>
                                    <span className="d-none d-sm-inline">SUCCESSFULLY EXECUTED</span>
                                </button>
                            </>
                        }
                    </>
                }
            </div>

            {
                loadingTxes &&
                <LoadingBars />
            }
            {
                !loadingTxes &&
                <>
                    {
                        !expandAll &&
                        <div className="fs-sm mt-2"><a href="javascript:;" className="text-decoration-none" onClick={() => setExpandAll(true)}>Show all wallets <i className="fas fa-chevron-down"></i></a></div>
                    }
                    {
                        expandAll &&
                        <div className="fs-sm mt-2"><a href="javascript:;" className="text-decoration-none" onClick={() => setExpandAll(false)}>Hide all wallets <i className="fas fa-chevron-up"></i></a></div>
                    }
                    {
                        distributionTransactions?.map((transaction: any, i: number) => (
                            <DistributionTransactionCard transaction={transaction} index={i} expandAll={expandAll} />
                        ))
                    }
                </>
            }
        </div>

    </div>;
};