import { get, patch, post } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-api.degencoinflip.com/v2';

export const processOgBurn = async (
  id: any,
  signature: any = null,
  Authorization: any = null
) => {
  const url = `${BASE_URL}/holders/og-burn/${id}`
  const response = await post(url, { signature }, { Authorization });
  return response?.payload;
};

export const initOgBurn = async (tokens: any[], Authorization: any) => {
  const url = `${BASE_URL}/holders/og-burn`
  const response = await post(url, { tokens }, { Authorization });
  return response?.data?.payload;
};


export const getBurnedTokens = async (walletId: any) => {
  const url = `${BASE_URL}/holders/og-burn?walletId=${walletId}`
  const response = await get(url);
  return response?.payload;
};
