import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { getPhantomWallet, getSlopeWallet, getSolflareWallet, getSolletWallet } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import "./@degen/styles/og.css";
import LayoutContainer from "./layout/layout";
import { Marketing } from "./pages/marketing/marketing";
import { CoinFlips } from "./pages/coin-flips/coin-flips";
import { MasterPlan } from "./pages/master-plan/master-plan";
import { Holders } from "./pages/holders/holders";
import { Distribution } from "./pages/distribution/distribution";
import { Distributions } from "./pages/distributions/distributions";
import { Evolve } from "./pages/evolve/evolve";

require('@solana/wallet-adapter-react-ui/styles.css');

const wallets = [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getSlopeWallet()];

const App = () => (
    <ConnectionProvider endpoint="http://127.0.0.1:8899">
        <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
                <BrowserRouter>
                    <Routes>
                        <Route element={<LayoutContainer />}>
                            <Route path="/" element={<Evolve />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </WalletModalProvider>
        </WalletProvider>
    </ConnectionProvider>
);

export default App;
