


import { get, post } from "../utils/http.util";

const BASE_URL = 'https://us-central1-degencoinflip.cloudfunctions.net';

export const getSiteStats = async () => {
    const url = `${BASE_URL}/site-stats`
    const response = await get(url);
    return response;
}