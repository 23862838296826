import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useState } from "react";
import { Link } from "react-router-dom";
import { CHALLENGES, constants } from "../../utils/constants";
import { FlipRow } from "../FlipRow";
import { FAQModal } from "../modals/FAQModal";
import { HowToPlayModal } from "../modals/HowToPlayModal";
import { ResponsibleModal } from "../modals/ResponsibleModal";

const DCF_ID = CHALLENGES[0].id;
const COIN_QUESTION_MARK = "https://i.imgur.com/OjGFzTQ.png";

const CoinFlipWizardHome = ({
  community,
  style,
  wallet,
  recentCoinFlips,
  loading,
  onNext
}: any) => {

  const [showFAQ, setShowFAQ] = useState(false);
  const handleFAQClose = () => setShowFAQ(false);
  const handleFAQOpen = () => setShowFAQ(true);

  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const handleHowToPlayClose = () => setShowHowToPlay(false);
  const handleHowToPlayOpen = () => setShowHowToPlay(true);

  const [showResponsible, setShowResponsible] = useState(false);
  const handleResponsibleClose = () => setShowResponsible(false);
  const handleResponsibleOpen = () => setShowResponsible(true);

  const goToNextPage = () => {
    onNext();
  }

  const Modals = () => {
    return (
      <>
        {
          showFAQ &&
          <FAQModal
            show={showFAQ}
            onHide={() => handleFAQClose()}
            wallet={wallet}
            styleCss={style}
          />
        }
        {
          showHowToPlay &&
          <HowToPlayModal
            show={showHowToPlay}
            onHide={() => handleHowToPlayClose()}
            styleCss={style}
          />
        }
        {
          showResponsible &&
          <ResponsibleModal
            show={showResponsible}
            onHide={handleResponsibleClose}
            styleCss={style}
          />
        }
      </>
    )
  }

  return (
    <div className="">
    {
        !wallet.connected &&
        <WalletMultiButton style={{ marginLeft: 'auto', marginRight: 'auto' }} />
      }
      {
        wallet.connected &&
        <>
          {
            !loading &&
            <>
              <button onClick={() => goToNextPage()} className="wallet-adapter-button wallet-adapter-button-trigger">
                Select Wallet
              </button>
              {/* <img onClick={() => goToNextPage()} className="cursor-pointer double-button my-3 img-fluid" src={community?.assets?.buttonDoubleOrNothing ?? constants.BUTTONS.DoubleOrNothing} alt="double or nothing" width="320px" height="100%" /> */}
              {/* <h6>CLICK TO SEE OPTIONS</h6> */}
            </>
          }
          {
            loading &&
            <>
              <div className="d-flex justify-content-center py-5 new-dark">
                <div className="la-ball-8bits">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </>
          }
        </>
      }

    </div>
    // <div className="text-start play step1 mt-md-5 pt-md-5 pt-4">
    //   <div className="">
    //     <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
    //       <div className="col-10 col-sm-8 col-lg-6">
    //         <img src={constants.BUTTONS.Processing} className="d-block mx-lg-auto img-fluid" alt="coin flipping" loading="lazy" />
    //       </div>
    //       <div className="col-lg-6">
    //         <h1 className="display-5 fw-bold lh-1 mb-3">DEGEN COIN FLIP</h1>
    //         <p className="lead">DCF has over +1m flips since we started and we are the #1 most trusted platform on Solana.</p>
    //         <div className="d-grid gap-2 d-md-flex justify-content-md-start">
    //           {
    //             !wallet.connected &&
    //             <WalletMultiButton style={{ marginLeft: 'auto', marginRight: 'auto' }} />
    //           }
    //           {
    //             wallet.connected &&
    //             <>
    //               {
    //                 !loading &&
    //                 <>
    //                   <img onClick={() => goToNextPage()} className="cursor-pointer double-button my-3" src={community?.assets?.buttonDoubleOrNothing ?? constants.BUTTONS.DoubleOrNothing} alt="double or nothing" width="100%" height="100%" />
    //                   {/* <h6>CLICK TO SEE OPTIONS</h6> */}
    //                 </>
    //               }
    //               {
    //                 loading &&
    //                 <>
    //                   <div className="d-flex justify-content-center py-5">
    //                     <div className="la-ball-8bits">
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                       <div />
    //                     </div>
    //                   </div>
    //                 </>
    //               }
    //             </>
    //           }

    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CoinFlipWizardHome;