import { useState } from "react";
import { Modal } from "react-bootstrap";
import { LoadingBars } from "../LoadingBars";

export function InfoModal({ styleCss, loading, isAuth, onLoginViaLedger, ...props }) {
    const [enabled, setEnabled] = useState(false);
    setTimeout(function () {
        setEnabled(true);
    }, 1000);
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName={styleCss + `-color`}
            centered
        >
            <Modal.Body className={"p-0"}>
                <div className="card card-user shadow-lg">
                    <div className={"card-body p-4" + (styleCss === 'dark' ? ' pb-0' : '')}>
                        <h4 className="text-light">How does it work?</h4>
                        <b><u>Before July 19th</u></b>:
                        <p className="fw-bold">
                            Exchange your DCF NFTs for <span className="text-success">1 WL + 28 cats each</span>. Cats will be airdropped shortly after mint, July 19th, 2022.
                        </p>
                        <b><u>After July 19th</u></b>:
                        <p className="fw-bold mb-0">
                            Exchange your DCF NFTs for 28 cats each + the amount of sol in distributions that you are owed.                        </p>
                    </div>
                    <div className="card-footer mt-3 text-center">
                        {
                            !loading &&
                            <>
                                <button
                                    className="btn btn-block w-100 btn-lg my-2 rounded-0 btn-warning" disabled={!enabled}
                                    onClick={props.onClose}>
                                    {isAuth ? `GOT IT` : 'ENTER'}
                                </button>
                                <a href="javascript:;" onClick={onLoginViaLedger}>USE LEDGER</a>
                            </>
                        }
                        {
                            loading &&
                            <div className="my-3">
                                <LoadingBars />
                            </div>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}





