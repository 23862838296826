


import { get, post } from "../utils/http.util";

const BASE_URL = process.env.REACT_APP_API_URL ?? 'https://dev-api.degencoinflip.com/v2';

export const startDistribution = async (snapshotId = '', totalSol = 0, signature = null, Authorization = '') => {
  const url = `${BASE_URL}/distributions`
  const response = await post(url, { snapshotId, totalSol, signature }, { Authorization });
  return response?.data?.payload;
}

export const processDistribution = async (id = '', signature = null, Authorization = '') => {
  const url = `${BASE_URL}/distributions/${id}`
  const response = await post(url, { signature }, { Authorization });
  return response?.data?.payload;
}

export const getDistributions = async () => {
  const url = `${BASE_URL}/distributions`;
  const response = await get(url);
  return response?.payload;
}

export const getDistribution = async (id: any) => {
  const url = `${BASE_URL}/distributions/${id}`;
  const response = await get(url);
  return response?.payload;
}

export const getDistributionHolders = async (id: any) => {
  const url = `${BASE_URL}/distributions/${id}/holders`;
  const response = await get(url);
  return response?.payload;
}

export const getDistributionTransactions = async (id: any) => {
  const url = `${BASE_URL}/distributions/${id}/transactions`;
  const response = await get(url);
  return response?.payload;
}

export const processDistributionTransaction = async (id = '', transactionId = '', signature = null, Authorization = '') => {
  const url = `${BASE_URL}/distributions/${id}/transactions/${transactionId}`
  const response = await post(url, { signature }, { Authorization });
  return response?.data?.payload;
}
